import React, { useState, Suspense, useEffect } from 'react';
import { Container, Form, Row, Col, FormGroup, Label, Input, Button, Spinner } from 'reactstrap';
import './index.scss';
import { useWindowSize } from 'react-use';

const Spline = React.lazy(() => import('@splinetool/react-spline'));

const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const ContattaciSection = () => {
  const { width } = useWindowSize();
  const [state, setState] = useState({
    isMobileView: false,
  });

  useEffect(() => {
    //992px is Large breakpoint of bootstrap
    setState((prev) => ({ ...prev, isMobileView: width <= 992 }));
  }, [width]);

  const formInputToVerify = ['FNAME', 'LNAME', 'EMAIL', 'MESSAGE', 'privacy'];
  const [invalidFields, setInvalidFields] = useState({
    FNAME: false,
    LNAME: false,
    EMAIL: false,
    MESSAGE: false,
    privacy: false,
  });
  const [loading, setLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const onClickSend = (event) => {
    event.preventDefault();

    const form = event.target.form;
    const formElements = form.elements;

    const newInvalidFields = {};
    for (let i = 0; i < formElements.length; i++) {
      const element = formElements[i];
      if ((element.tagName === 'INPUT' || element.tagName === 'TEXTAREA') && formInputToVerify.includes(element.name)) {
        if (element.type === 'checkbox' && !element.checked) {
          newInvalidFields[element.name] = true;
        } else if (element.type !== 'checkbox' && !!element.value === false) {
          newInvalidFields[element.name] = true;
        } else if (element.name === 'EMAIL' && !emailPattern.test(element.value)) {
          newInvalidFields[element.name] = true;
        }
      }
    }
    setInvalidFields(newInvalidFields);

    if (Object.keys(newInvalidFields).length === 0) {
      setLoading(true);

      doEmailSubscription()
        .then(() => {
          console.log('Form inviato');
          setLoading(false);
          setFormSubmitted(true);
        })
        .catch((error) => {
          console.error('Network error', error);
          alert('Error');
          setLoading(false);
          setFormSubmitted(true);
        });
    }
  };

  const doEmailSubscription = () => {
    const data = {
      EMAIL: document.getElementById('email').value,
      MMERGE3: document.getElementById('messaggio').value,
      FNAME: document.getElementById('nome').value,
      LNAME: document.getElementById('cognome').value,
      PHONE: document.getElementById('telefono').value,
      tags: '2570650',
      b_6676987e56bb537bfac88dc6c_3ddbd6095b: document.querySelector('input[name="b_6676987e56bb537bfac88dc6c_3ddbd6095b"]').value || '',
    };

    return fetch('https://soulbit.us18.list-manage.com/subscribe/post?u=6676987e56bb537bfac88dc6c&id=3ddbd6095b&f_id=001310e7f0', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams(data).toString(),
      mode: 'no-cors',
    });
  };

  const onClickSendAgain = (event) => {
    event.preventDefault();

    setFormSubmitted(false);
    setLoading(false);
  };

  const renderForm = () => {
    return (
      <>
        <div className="contattaci-title-container">
          <span className="contattaci-title">
            Sblocchiamo il potenziale digitale,
            <span className="contattaci-title-colored"> insieme.</span>
          </span>
        </div>

        <Form className="form-container">
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label className={invalidFields.FNAME ? 'form-label-invalid' : undefined} for="nome">
                  Nome*
                </Label>
                <Input id="nome" name="FNAME" className={invalidFields.FNAME ? 'form-control-invalid' : undefined} />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label className={invalidFields.LNAME ? 'form-label-invalid' : undefined} for="cognome">
                  Cognome*
                </Label>
                <Input id="cognome" name="LNAME" className={invalidFields.LNAME ? 'form-control-invalid' : undefined} />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="telefono">Telefono</Label>
                <Input id="telefono" name="PHONE" />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label className={invalidFields.EMAIL ? 'form-label-invalid' : undefined} for="email">
                  Email*
                </Label>
                <Input id="email" name="EMAIL" className={invalidFields.EMAIL ? 'form-control-invalid' : undefined} />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label className={invalidFields.MESSAGE ? 'form-label-invalid' : undefined} for="messaggio">
                  Messaggio*
                </Label>
                <Input type="textarea" id="messaggio" name="MESSAGE" className={invalidFields.MESSAGE ? 'form-control-invalid' : undefined} />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <FormGroup check>
              <Input id="privacy" name="privacy" type="checkbox" className={invalidFields.privacy ? 'invalid' : undefined} />
              <Label className={invalidFields.privacy ? 'form-check-label-invalid' : undefined} check for="privacy">
                Ho letto la privacy policy*
              </Label>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup check>
              <Input id="subscribe" name="subscribe" type="checkbox" />
              <Label check for="subscribe">
                Desidero ricevere delle email pubblicitarie per restare in contatto con Soulbit
              </Label>
            </FormGroup>
          </Row>
          <Row>
            <div aria-hidden="true" style={{ position: 'absolute', left: '-5000px' }}>
              <input type="text" name="b_6676987e56bb537bfac88dc6c_3ddbd6095b" tabindex="-1" value="" />
            </div>
          </Row>
            <>
                {!loading ?
                    <Button size="lg" color="primary" disabled={loading} onClick={onClickSend}>
                        Invia
                    </Button>
                    :
                    <Spinner className={"spinner-contattaci"} />
                }
            </>
        </Form>
      </>
    );
  };

  const renderFormSubmitted = () => {
    return (
      <div className="contattaci-form-submitted-container">
        <span className="title">Messaggio inviato!</span>
        <span className="subtitle">Ti riceveremo prima possibile.</span>
        <Form className="form-container">
          <Row>
            <Col>
              <Button fluid={false} color="primary" onClick={onClickSendAgain}>
                Inviane un altro
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  };
  return (
    <Container id="contact" className={`contattaci-section`}>
      <div className="contattaci-container">{formSubmitted ? renderFormSubmitted() : renderForm()}</div>

      {!state.isMobileView && (
        <Suspense fallback={<div>Loading...</div>}>
          <Spline className="contattaci-animation-container" scene={'https://prod.spline.design/fkSOsUphPtZSgw6m/scene.splinecode'} />
        </Suspense>
      )}
    </Container>
  );
};

export default ContattaciSection;
