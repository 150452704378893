import React from 'react';
import { Container } from 'reactstrap';
import text from '../../../../text.json';
import './index.scss';

const MissionSection = () => {
  return (
    <Container className="mission-section" id="mission">
      <div className="image">
        <div className="chip">
          <span>MISSION</span>
        </div>
        <span className="mission-title">{text['MISSION'].title}</span>
      </div>
      <div className="mission-description-container">
        <p className="mission-description">{text['MISSION'].description}</p>
      </div>
    </Container>
  );
};

export default MissionSection;
