import React from 'react';

import Landing from './pages/Landing';
import NotFound from './pages/NotFound';
import UnderMaintenance from './pages/UnderMaintenance';

const isUnderMaintenance = Number(process.env.REACT_APP_MAINTENANCE_ACTIVE) === 1;

const routes = [
  { path: '/', component: isUnderMaintenance ? <UnderMaintenance /> : <Landing /> },
  { path: '*', component: <NotFound /> },
];

export default routes;
