import React from 'react';
import { Container } from 'reactstrap';
import { useWindowSize } from 'react-use';
import './index.scss';

const getNumberOfDividerToRender = (width) => {
  if (width >= 768) {
    return 5;
  } else {
    return 3;
  }
};

const DividerSection = () => {
  const { width } = useWindowSize();

  const imagesDivide = [
    <img className="image-divider" alt="star" src={require('./separator-0.png')}></img>,
    <img className="image-divider" alt="star" src={require('./separator-1.png')}></img>,
    <img className="image-divider" alt="star" src={require('./separator-2.png')}></img>,
    <img className="image-divider" alt="star" src={require('./separator-3.png')}></img>,
    <img className="image-divider" alt="star" src={require('./separator-4.png')}></img>,
  ].splice(0, getNumberOfDividerToRender(width));
  return <Container className={`divider-section`}>{imagesDivide.map((image) => image)}</Container>;
};

export default DividerSection;
