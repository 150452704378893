import React from 'react';
import { Container, Row } from 'reactstrap';
import UnderMaintenanceSvg from './under_maintenance.svg';
import './index.scss';

const UnderMaintenance = () => {
  return (
    <Container className="under-maintenance-page">
      <Row className="justify-content-center">
        <img alt="under_maintenance logo" src={UnderMaintenanceSvg}></img>
        <span className="first-label-under-maintenance">
          Il sito è in <span className="second-label-under-maintenance">manutenzione.</span>
        </span>
      </Row>
    </Container>
  );
};

export default UnderMaintenance;
