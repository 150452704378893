import React from 'react';
import { Container } from 'reactstrap';
import StarSvg from './star.svg';
import './index.scss';

const ValuesSection = (props) => {
  const { id, imageSrc, values } = props;
  const flexDirection = 'vision-row-reverse';

  return (
    <Container id={id} className={`vision-section ${flexDirection}`}>
      <img src={`/assets/images/vision/${imageSrc}`} className="img-fluid" alt="blog" />
      <div className="descriptions-container">
        <div className="chip">
          <span>{'VALORI'}</span>
        </div>
        <>
          {values.map((value, index) => {
            return (
              <div key={index} className="value">
                <img className="star" alt="star" src={StarSvg}></img>
                <span className="highlighted">{value.highlighted}</span>
                <span className="not-highlighted">{value.description}</span>
              </div>
            );
          })}
        </>
      </div>
    </Container>
  );
};

export default ValuesSection;
