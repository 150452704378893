import React from 'react';
import text from '../../../../text.json';
import './index.scss';

const Main = () => {
  return (
    <React.Fragment>
      <div id="main" class="soulbit-main-section">
        <div class="logo-container">
          <img src={`/assets/images/soulbit_logo_main_section.svg`} alt="soulbit logo" />
          <span class="caption">
            <span class="first-line">{text['MAIN'].title}</span>
            <span class="second-line">{text['MAIN'].description}</span>
          </span>
        </div>
        <div class="main-section-subtitle">
          <span class="strong">Soulbit</span> {text['MAIN'].caption}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Main;
