import React, { useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y, EffectCoverflow } from 'swiper/modules';

import './MyTeam.scss';

const MyTeam = () => {
    const { width } = useWindowSize();

    useEffect(() => {
        //992px is Large breakpoint of bootstrap
        setState((prev) => ({ ...prev, isMobileView: width <= 992 }));
    }, [width]);

    const [state, setState] = useState({
        isMobileView: false,
        teams: [
            {
                image: '/assets/images/team/federico-costa.png',
                title: 'Federico Costa',
                desc: 'Founder',
            },
            {
                image: '/assets/images/team/carlo-amenta.png',
                title: 'Carlo Amenta',
                desc: 'Software Engineer',
            },
            {
                image: '/assets/images/team/simone-di-mauro.png',
                title: 'Simone Di Mauro',
                desc: 'Software Engineer',
            },
            {
                image: '/assets/images/team/flora-damiano.png',
                title: 'Flora Damiano',
                desc: 'Product Designer',
            },
            {
                image: '/assets/images/team/francesca-diana.png',
                title: 'Francesca Diana',
                desc: 'Product Designer',
            },
        ],
    });

    const chunks = state.teams.reduce((acc, _, i) => (i % 3 ? acc : [...acc, state.teams.slice(i, i + 3)]), []);

    const renderTeamMember = (member, index) => {
        return (
            <div
                className="member"
                key={index}
                style={{
                    '--team-member-image': `url(${member.image})`,
                }}
            >
                <div className="info">
                    <span className="name">{member.title}</span>
                    <span className="title">{member.desc}</span>
                </div>
            </div>
        );
    };

    const renderDesktopView = () => {
        return chunks.map((row, index) => (
            <div className="row" key={index}>
                <div className="row-container">{row.map((member, rowIndex) => renderTeamMember(member, rowIndex))}</div>
            </div>
        ));
    };

    const renderMobileView = () => {
        return (
            <>
                <Swiper
                    slidesPerView={3}
                    spaceBetween={30}
                    centeredSlides={true}
                    loop={true}
                    pagination={{ clickable: true }}
                    navigation={true}
                    autoplay={{ delay: 3000, disableOnInteraction: false }}
                >
                    {state.teams.map((member, index) => (
                        <SwiperSlide style={{
                            minWidth: '250px'
                        }}
                            key={index}>{renderTeamMember(member, index)}</SwiperSlide>
                    ))}
                </Swiper>
            </>
        );
    };

    return (
        <React.Fragment>
            <div className="soulbit-my-team container" id="team">
                <div className="chip">
                    <span>IL TEAM</span>
                </div>
                <div className="people-container">{state.isMobileView ? renderMobileView() : renderDesktopView()}</div>
            </div>
        </React.Fragment>
    );
};

export default MyTeam;
