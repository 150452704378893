import React, { useState, useRef } from 'react';
import { Container, Row } from 'reactstrap';
import text from '../../../../text.json';
import StarSvg from './star.svg';
import './index.scss';

const ServicesSection = () => {
  const [serviceSelectedId, setServiceSelectedId] = useState('2-0');
  const services = useRef([
    [
      { id: '0-0', label: text['SERVIZI'][0].title, description: text['SERVIZI'][0].description },
      { id: '0-1', label: text['SERVIZI'][1].title, description: text['SERVIZI'][1].description },
    ],
    [
      { id: '1-0', label: text['SERVIZI'][2].title, description: text['SERVIZI'][2].description },
      { id: '1-1', label: text['SERVIZI'][3].title, description: text['SERVIZI'][3].description },
    ],
    [{ id: '2-0', label: text['SERVIZI'][4].title, description: text['SERVIZI'][4].description }],
  ]).current;

  const runDescriptionAnimation = () => {
    const serviceDescription = document.querySelector('.service-description');
    serviceDescription.classList.add('text-change-animation');

    // Rimuovi la classe dopo che l'animazione è terminata
    serviceDescription.addEventListener(
      'animationend',
      () => {
        serviceDescription.classList.remove('text-change-animation');
      },
      { once: true }
    );
  };

  const onPressService = (id) => {
    setServiceSelectedId(id);
    runDescriptionAnimation();
  };

  const renderServices = (servicesRow, index, allServicesRow) => {
    const className = `services-row services-row-${index}`;

    return (
      <div className={className} index={`${index}`}>
        {servicesRow.map((service) => {
          const isSelected = service.id === serviceSelectedId;
          return (
            <div className="service-container" key={service.id} onClick={() => onPressService(service.id)}>
              <img className="service-start" alt="star" src={StarSvg}></img>
              {isSelected ? (
                <>
                  <div className="service-start-selected"></div>
                  <span className="service-title service-title-selected">{service.label}</span>
                </>
              ) : (
                <span className="service-title">{service.label}</span>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const getServiceFromId = (selectedServiceId) => {
    return services.find((serviceGroup) => serviceGroup.some((service) => service.id === selectedServiceId)).find((service) => service.id === selectedServiceId);
  };

  const getServiceSelected = () => {
    return getServiceFromId(serviceSelectedId);
  };

  const selectedServiceDescription = getServiceSelected().description;

  return (
    <Container className="services-section" id="services">
      <Row className="justify-content-center">
        <div className="chip">
          <span>SERVIZI</span>
        </div>
        <span className="first-label">Una galassia di</span>
        <span className="second-label">possibilità.</span>
      </Row>
      <Row className="services-container">
        {services.map(renderServices)}
        <div className="service-description">
          <span>{selectedServiceDescription}</span>
        </div>
      </Row>
    </Container>
  );
};

export default ServicesSection;
