import React, { useEffect, useState } from "react";
import {
    Nav,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink,
    Collapse,
} from "reactstrap";
import './Navbar.scss';

const Navbar = () => {

    const navItems = [
        { id: 1, idnm: 'services', navheading: 'Servizi' },
        { id: 2, idnm: 'team', navheading: 'Chi Siamo' },
        { id: 3, idnm: 'contact', navheading: 'Contattaci' },
    ];

    const [state, setState] = useState({
        isOpenMenu: false,
        navClass: ""
    })


    const toggle = () => {
        setState({ isOpenMenu: !state.isOpenMenu });
    };

    const activateScrollListener = () => {
        document.querySelectorAll('a[href^="#"]').forEach(anchor => {
            anchor.addEventListener('click', function (e) {
                e.preventDefault();

                const targetElement = document.querySelector(this.getAttribute('href'));
                const offsetPosition = targetElement.getBoundingClientRect().top + window.scrollY - 115;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth'
                });

                setState((prev) => ({...prev, isOpenMenu: false}));
            });
        });
    }

    useEffect(() => {
        activateScrollListener();
    }, [])

    return (
        <React.Fragment>
            <div id="navbar">
                <nav
                    className={`navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark ${state.navClass} ${state.isOpenMenu && 'show'}`}
                >
                    <div className="container-lg">
                        <div class="button-navigation-container">
                            <NavbarBrand className="logo" href="/">
                                <img
                                    src={`/assets/images/soulbit_logo.svg`}
                                    alt="soulbit logo" />
                                Soulbit
                            </NavbarBrand>
                            <NavbarToggler onClick={toggle}>
                                <i className={`mdi mdi-${state.isOpenMenu ? 'close' : 'menu'}`}
                                    style={{ color: 'white' }}
                                />
                            </NavbarToggler>
                        </div>

                        <Collapse
                            id="navbarCollapse"
                            className="navigation-container"
                            isOpen={state.isOpenMenu}
                            navbar
                        >

                            <Nav navbar className="navbar-center" id="mySidenav">
                                {navItems.map((item, key) => (
                                    <NavItem
                                        key={key}
                                        className={item.navheading === "Home" ? "active" : ""}
                                    >
                                        <NavLink href={"#" + item.idnm}>
                                            {" "}
                                            {item.navheading}
                                        </NavLink>
                                    </NavItem>
                                ))}
                            </Nav>
                        </Collapse>
                    </div>
                </nav>
            </div>
        </React.Fragment>
    );

}

export default Navbar;
