import React from 'react';
import { Container } from 'reactstrap';
import NotfoundLogo from './404.svg';
import './index.scss';

export default function NotFound() {
    return (
        <div className="not-found-page">
            <Container>
                <img
                    className="img-fluid"
                    alt="not found logo"
                    src={NotfoundLogo}
                ></img>
            </Container>
        </div>
    );
};
