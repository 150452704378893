import React from 'react';
import './index.scss';

import Navbar from '../../components/Navbar/Navbar';
import Main from './Sections/Main';
import Mission from './Sections/Mission';
import Service from './Sections/Services';
import Vision from './Sections/Vision';
import ValuesSection from './Sections/Valori';
import Divider from './Sections/Divider';
import Contattaci from './Sections/Contattaci';
import Footer from '../../components/Footer/footer';
import MyTeam from '../../components/MyTeam/MyTeam';

import text from '../../text.json';

const Index1 = () => {
  return (
    <React.Fragment>
      <div className="soulbit-landing-page">
        {/* Importing Navbar */}
        <Navbar />

        {/* section */}
        <Main />

        <Service />

        <Mission />

        <Vision id="vision" chipText={'Vision'} mainTitle={text['VISION'].title} mainTitleColored={text['VISION'].title_highlighted} description={text['VISION'].description} reverse={false} imageSrc={'./vision.png'} />

        <ValuesSection id="valori" imageSrc={'valori.png'} values={text['VALORI']} />

        <MyTeam />

        <Divider />

        <Contattaci />
        {/* footer */}
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Index1;
