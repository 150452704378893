import React from 'react';
import { Container } from 'reactstrap';
import './footer.scss';

const Footer = () => {
  return (
    <React.Fragment>
      <div id="footer" className="soulbit-footer">
        <Container>
          <div className="logo">
            {}
            <img src={`/assets/images/soulbit_logo.svg`} alt="soulbit logo" />
            Soulbit
          </div>
          <div className="copyright">Copyright @Soulbit 2024</div>
          <div className="more-info">
            <div className="row">
              <div className="col-md-4 text left">
                Soulbit di Rosario Federico Costa
                <br />
                P.IVA 06038820871
                <br />
                Sede legale: Via Trieste, 25 , 95127 Catania
              </div>
              <div className="col-md-4 text center"></div>
              <div className="col-md-4 text right link" onClick={() => window.open('/privacy_policy.pdf', '_blank')}>
                Privacy Policy
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Footer;
