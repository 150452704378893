import React from "react";
import routes from "./routes";
import { Route, Routes } from "react-router-dom";

function App() {
    return (
        <Routes>
            {routes.map((route, idx) => (
                <Route path={route.path} element={route.component} key={idx} />
            ))}
        </Routes>
    );
}

export default App;
