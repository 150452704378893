import React from 'react';
import { Container } from 'reactstrap';
import './index.scss';

const VisionSection = (props) => {
    const { id, chipText, mainTitle, mainTitleColored, description, reverse, imageSrc } = props;
    const flexDirection = reverse ? 'vision-row-reverse' : 'vision-row';

    return (
        <Container id={id} className={`vision-section ${flexDirection}`}>
            <img src={`/assets/images/vision/${imageSrc}`} className="img-fluid" alt="blog" />
            <div className="descriptions-container">
                <div className="chip">
                    <span>{chipText}</span>
                </div>
                <div className="main-title">
                    <span className="main-text">
                        {mainTitle}
                        <span className="colored-text"> {mainTitleColored}</span>
                    </span>
                </div>
                <div className="description">
                    <span>{description}</span>
                </div>
            </div>
        </Container>
    );
};

export default VisionSection;
